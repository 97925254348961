import * as yup from 'yup';

export const addressSchema = (required: boolean = false) => {
    return yup.object().shape({
        raw: (required ? yup.string().required() : yup.string()).label('Address'),
        streetNumber: yup.string().label('Street Number'),
        route: yup.string().label('Street'),
        locality: yup.string().label('City'),
        postalCode: yup.string().label('Postal Code'),
        state: yup.string().label('State'),
        stateCode: yup.string().label('State Code'),
        country: yup.string().label('Country'),
        countryCode: yup.string().label('Country Code'),
        latitude: yup.number().label('Latitude'),
        longitude: yup.number().label('Longitude'),
    });
};
